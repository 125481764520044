:root {
  --wt-black: #000000; /* black */
  --wt-secondary-dark: #006547; /* emerald-800 */
  --wt-secondary: #00aa77; /* emerald-500 */
  --wt-secondary-ligt: #00b27c; /* emerald-500 */
  --wt-bg-dark: #0f172a; /* slate-900 */
  --wt-bg: #1c073a; /* slate-900 */
  --wt-bg--light: #230351; /* indigo-950 */
  --wt-info: #4b9eec; /* blue-400 */
  --wt-success: #7add8a; /* green-300 */
  --wt-accent: #d9d9d9; /* zinc-300 */
  --wt-primary: #ef4444; /* red-500 */
  --wt-warning: #fcebcd /* orange-100 */;
}
